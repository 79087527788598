import React from "react";
import { FaCheck } from "react-icons/fa";
import styled from "styled-components";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { HeaderPlaceholder } from "../styles/ui.styles";

const Services: React.FC = () => {
  return (
    <>
      <SEO title={"Services"} description={"Services"} />
      <Layout>
        <HeaderPlaceholder />
        <Wrapper>
          <h1>Services</h1>

          <Grid>
            <div>
              <h3>Performance Analysis</h3>
              <p>
                Over the years we have developed a large set of tools and
                methods specifically tailored for yacht racing. We offer
                personalized data analysis and reports on a daily basis during
                your trainings and regattas. Either onsite or remotely we will
                generate a complete set of reports to help debrief your day. Get
                a daily feedback on calibration, starting and performance levels
                as well as a full event report at the end of the regatta to
                develop and adjust your boats targets, sail cross-overs and
                polars.
              </p>
            </div>
            <div>
              <p style={{ marginLeft: "0px" }}>
                It could includes the followings:
              </p>
              <ul>
                <li>
                  <StyledListElement>
                    <FaCheck />
                    Configure your existing sailing instruments
                  </StyledListElement>
                </li>
                <li>
                  <StyledListElement>
                    <FaCheck />
                    Calibration
                  </StyledListElement>
                </li>
                <li>
                  <StyledListElement>
                    <FaCheck />
                    Polar diagram update
                  </StyledListElement>
                </li>
                <li>
                  <StyledListElement>
                    <FaCheck />
                    Save the data in database
                  </StyledListElement>
                </li>
              </ul>
            </div>
          </Grid>
          <h3>Marine electronics </h3>
          <p>
            At Fastrrr, we offer a comprehensive sales and installation service
            including, system design, integration and installation, on and off
            site servicing, diagnostics of existing equipment. We are working
            with the leading manufacturers such as Raymarine, B&G, Garmin,
            Simrad, and more.{" "}
          </p>
        </Wrapper>
      </Layout>
    </>
  );
};

export default Services;

const Wrapper = styled.div`
  max-width: 1600px;
  margin: 0 auto;
  padding: 0 var(--gutter);
  padding-bottom: 100px;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 32px;

  ul {
    list-style-type: none;
    padding-left: 0;
  }

  div:nth-child(2) {
    padding: 32px;
    background: #f3f3f3;
    p {
      margin-left: 32px;
    }
  }

  li {
    text-decoration: none;
    svg {
      width: 16px;
      height: 16px;
      fill: var(--color-orange);
      margin-right: 16px;
    }
  }

  strong {
    color: #000;
  }

  @media (max-width: 992px) {
    grid-template-columns: 1fr;
  }
`;

const StyledListElement = styled.p`
  display: flex;
  align-items: center;
  svg {
    min-width: 16px;
  }
`;
